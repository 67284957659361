var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginView3",style:({
    'background-image': `url(${_vm.bg})`,
    'background-color': _vm.loginBgColor,
  })},[_c('div',{staticClass:"item_box"},[(_vm.logo)?_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"logo"}}):_vm._e(),_c('div',{ref:"loading_line",staticClass:"loading_line"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(this.color_line_vlaue)+"%")]),_c('div',{ref:"color_line",staticClass:"color_line",style:({ 'background-color': _vm.themeColor })})]),_c('div',{ref:"login_box",staticClass:"login_box",style:({ border: `2px ${_vm.themeColor} solid` })},[_c('div',{staticClass:"input_box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account),expression:"account"}],staticClass:"input",style:(_vm.placeHolderStyle),attrs:{"type":"text","placeholder":_vm.$t(`_accountPlaceholder`)},domProps:{"value":(_vm.account)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()},"input":function($event){if($event.target.composing)return;_vm.account=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",style:(_vm.placeHolderStyle),attrs:{"type":"password","placeholder":_vm.$t(`_passwordPlaceholder`)},domProps:{"value":(_vm.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"btn",style:({
            'background-color': _vm.themeColor,
            color: _vm.btnColor,
          }),on:{"click":function($event){$event.preventDefault();return _vm.login()}}},[_vm._v(" "+_vm._s(_vm.$t(`_login`))+" ")]),_c('div',{staticClass:"btn",style:({
            'background-color': _vm.themeColor,
            color: _vm.btnColor,
          }),on:{"click":function($event){$event.preventDefault();return _vm.demoFun.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("_testplay"))+" ")])])])]),_c('div',{ref:"mobile_lan",staticClass:"mobile_lan"},_vm._l((_vm.languages),function(language,index){return _c('div',{key:index,staticClass:"lan_btn",on:{"click":function($event){index == 0 ? _vm.mobilelanFun() : _vm.selectLang(language.name)}}},[_c('div',{staticClass:"lan_img",style:({ backgroundImage: `url(${language.img})` })})])}),0),_c('div',{staticClass:"version"},[_vm._v(_vm._s(_vm.$t("_version"))+" 1.0.0")]),(_vm.girlImage)?_c('div',{staticClass:"girl",style:({ backgroundImage: `url(${_vm.girl})` })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }