import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: eng,
  fallbackLocale: 'eng',
  messages: { eng, zht, zhs, ko, ja },
  silentTranslationWarn: true
});

export default i18n;
