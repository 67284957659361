<template>
  <div
    class="loginView3"
    :style="{
      'background-image': `url(${bg})`,
      'background-color': loginBgColor,
    }"
  >
    <div class="item_box">
      <img :src="logo" alt="logo" class="logo" v-if="logo" />

      <div class="loading_line" ref="loading_line">
        <div class="number">{{ this.color_line_vlaue }}%</div>
        <div
          class="color_line"
          ref="color_line"
          :style="{ 'background-color': themeColor }"
        >
          <!-- <div class="number">{{ this.color_line_vlaue }}%</div> -->
        </div>
      </div>

      <div
        class="login_box"
        ref="login_box"
        :style="{ border: `2px ${themeColor} solid` }"
      >
        <!-- <div class="img"></div> -->
        <div class="input_box">
          <input
            class="input"
            v-model="account"
            type="text"
            :placeholder="$t(`_accountPlaceholder`)"
            @keyup.enter="login()"
            :style="placeHolderStyle"
          />
          <input
            class="input"
            v-model="password"
            type="password"
            :placeholder="$t(`_passwordPlaceholder`)"
            @keyup.enter="login()"
            :style="placeHolderStyle"
          />
          <div
            class="btn"
            @click.prevent="login()"
            :style="{
              'background-color': themeColor,
              color: btnColor,
            }"
          >
            {{ $t(`_login`) }}
          </div>
          <div
            class="btn"
            @click.prevent="demoFun"
            :style="{
              'background-color': themeColor,
              color: btnColor,
            }"
          >
            {{ $t("_testplay") }}
          </div>
        </div>
      </div>
    </div>

    <div class="mobile_lan" ref="mobile_lan">
      <div
        class="lan_btn"
        v-for="(language, index) in languages"
        :key="index"
        @click="index == 0 ? mobilelanFun() : selectLang(language.name)"
      >
        <div
          class="lan_img"
          :style="{ backgroundImage: `url(${language.img})` }"
        ></div>
      </div>
    </div>

    <div class="version">{{ $t("_version") }} 1.0.0</div>

    <div
      v-if="girlImage"
      class="girl"
      :style="{ backgroundImage: `url(${girl})` }"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "md5";
import { Loading } from "element-ui";
import Cookies from "js-cookie";

export default {
  components: {},
  data() {
    return {
      loginBgColor: glob.loginBgColor,
      logo:
        glob.logo && glob.showLogo
          ? require(`@/assets/images/${glob.logo}`)
          : null,
      tokenLogo: glob.logo ? require(`@/assets/images/${glob.logo}`) : null,
      loginBgImage: glob.loginBgImage
        ? require(`@/assets/images/${glob.loginBgImage}`)
        : null,
      loginBgImagePhone: glob.loginBgImagePhone
        ? require(`@/assets/images/${glob.loginBgImagePhone}`)
        : null,
      languages: [
        {
          img: require("@/assets/images/FlagC/CN.svg"),
          name: "zhs",
        },
        {
          img: require("@/assets/images/FlagC/HK.svg"),
          name: "zht",
        },
        {
          img: require("@/assets/images/FlagC/US.svg"),
          name: "eng",
        },
        {
          img: require("@/assets/images/FlagC/KR.svg"),
          name: "ko",
        },
        {
          img: require("@/assets/images/FlagC/JP.svg"),
          name: "ja",
        },
      ],
      color_line_vlaue: 0,
      currentLan: glob.len,
      account: "",
      password: "",
      themeColor: glob.themeColor,
      inputColor: glob.inputColor,
      inputBgColor: glob.inputBgColor,
      inputPlaceHolder: glob.inputPlaceHolder,
      lanSelectBottom: glob.lanSelectBottom,
      titleLinearColor: glob.titleLinearColor,
      btnColor: glob.btnColor,
      girlImage: glob.girlImage,
      girl: require("@/assets/images/redlantern/girl.png"),
    };
  },
  computed: {
    bg() {
      // console.log(window.innerWidth);
      if (window.innerWidth > 500) {
        return this.loginBgImage;
      }
      return this.loginBgImagePhone;
    },
    placeHolderStyle() {
      return {
        "--placeholderColor": this.inputPlaceHolder,
        "background-color": this.inputBgColor,
        color: this.inputColor,
      };
    },
    titleStyle() {
      if (this.titleLinearColor.length > 1) {
        let styleText = "";
        for (let i = 0; i < this.titleLinearColor.length; i++) {
          styleText += this.titleLinearColor[i];
          if (i + 1 < this.titleLinearColor.length) {
            styleText += ", ";
          }
        }
        return {
          "--titleLinearColor": `-webkit-linear-gradient(-90deg, ${styleText})`,
          "--titleColor": this.titleLinearColor[0],
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        };
      }
      return {
        "--titleColor": this.titleLinearColor[0],
      };
    },
    backgroundStyle() {
      return {
        "--backgroundColor": `rgba(0, 0, 0, ${this.backgroundOpacity})`,
      };
    },
  },
  created() {},
  mounted() {
    this.runLoadingFun();

    var lan = localStorage.getItem("currentLan");

    if (!lan) {
      lan = this.currentLan;
    }
    // localStorage.setItem("currentLan", lan);

    let new_languages = [];
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].name !== this.currentLan) {
        new_languages.push(this.languages[i]);
      } else {
        new_languages.unshift(this.languages[i]);
      }
    }
    this.languages = new_languages;

    this.$i18n.locale = lan;
    console.log("lan: ", lan);
    this.selectLang(lan);
    this.showLangs = false;
  },
  methods: {
    selectLang(lan) {
      // console.log(language, lan, index);

      let lanCookies = "zht";
      switch (lan) {
        case "eng":
          lanCookies = "eng";
          break;

        case "zht":
          lanCookies = "zht";
          break;

        case "zhs":
          lanCookies = "zhs";
          break;

        case "ja":
          lanCookies = "jpn";
          break;

        case "ko":
          lanCookies = "kor";
          break;

        default:
          lanCookies = "zht";
          break;
      }
      Cookies.set("lan", lanCookies);

      this.showLangs = !this.showLangs;
      if (lan === this.currentLan) {
        return;
      } else {
        localStorage.setItem("currentLan", lan);
        // localStorage.setItem("currentLanIdx", index);
        // localStorage.setItem("language", JSON.stringify(language));
        this.currentLan = lan;
        this.$i18n.locale = lan;

        let new_languages = [];
        for (let i = 0; i < this.languages.length; i++) {
          if (this.languages[i].name !== lan) {
            new_languages.push(this.languages[i]);
          } else {
            new_languages.unshift(this.languages[i]);
          }
        }
        this.languages = new_languages;
      }
      this.$nextTick(() => {
        this.$refs.mobile_lan.style.height = "40px";
      });
    },
    mobilelanFun() {
      if (this.$refs.mobile_lan.style.height !== "fit-content") {
        this.$refs.mobile_lan.style.height = "fit-content";
      } else {
        this.$refs.mobile_lan.style.height = "40px";
      }
    },
    login() {
      const loadingInstance = Loading.service({
        lock: true,
        text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 檢查帳號或密碼是否為空
      const loginParams = {
        account: this.account,
        passwd: md5(`${this.password}`),
        game: true,
      };

      if (!this.account.length || !this.password.length) {
        // this.$refs.form.classList.add("shake");
        // this.$refs.incorrect.classList.add("incorrect");
        loadingInstance.close();
        // this.incorrect = true;
        // setTimeout(() => {
        //   this.$refs.form.classList.remove("shake");
        // }, 1000);
        this.$message({
          // message: this.$t("_wrong"),
          message: this.$t("_empty"),
          type: "error",
          center: true,
        });
      } else {
        this.incorrect = false;
        // service.login(loginParams);

        // const windowReference = window.open();

        axios
          .post("/api/login", loginParams)
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              axios
                .get("/api/bet360/oauth")
                .then((res) => {
                  console.log(res);
                  if (res.data.result == "success") {
                    // loadingInstance.close();
                    this.makeUrl(true, res.data.url);
                  } else {
                    loadingInstance.close();
                    if (res.data.msg == "Invalid target id") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else if (res.data.msg == "Invalid account or password") {
                      this.$message({
                        message: this.$t("_wrong"),
                        type: "error",
                        center: true,
                      });
                    } else {
                      this.$message({
                        message: res.data.msg,
                        type: "error",
                        center: true,
                      });
                    }
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: this.$t("_networkError"),
                    type: "error",
                    center: true,
                  });
                  loadingInstance.close();

                  console.log(err);
                });
            } else {
              loadingInstance.close();
              if (res.data.msg == "Invalid target id") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else if (res.data.msg == "Invalid account or password") {
                this.$message({
                  message: this.$t("_wrong"),
                  type: "error",
                  center: true,
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  center: true,
                });
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              if (
                err.response.status === 403 &&
                err.response.data.msg === "Your ip is not in the whitelist"
              ) {
                this.$message({
                  message: this.$t("_ipNotInWhitelist"),
                  type: "error",
                  center: true,
                });
                // loadingInstance.close();
              } else {
                this.$message({
                  message: this.$t("_networkError"),
                  type: "error",
                  center: true,
                });
                loadingInstance.close();
              }
            }
            loadingInstance.close();
            console.log(err);
          });
      }
    },
    makeUrl(type, url) {
      // console.log("makeUrl", type, url);
      let jumpUrl = url;
      if (type) {
        jumpUrl += `&101token=${Cookies.get("token")}`;
      }
      jumpUrl += `&remove101token=${true}`;
      jumpUrl += `&101api=${glob.managebackendurl}`;
      jumpUrl += `&101domain=${window.location.href.split("#")[0]}`;

      jumpUrl += `&101logo=${
        window.location.href.split("#")[0]
      }${this.tokenLogo.slice(1)}`;
      jumpUrl += `&101title=${glob.gameTitle}`;
      if (glob.phoneMode) {
        jumpUrl += `&phoneMode=${glob.phoneMode}`;
      }
      if (glob.free) {
        jumpUrl += `&free=${glob.free}`;
      }

      // console.log(jumpUrl);
      window.location.replace(jumpUrl);
    },

    demoFun() {
      const loadingInstance = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // const windowReference = window.open();

      axios
        .get("/api/bet360/testplay")
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            // loadingInstance.close();
            this.makeUrl(false, res.data.url);
          } else {
            loadingInstance.close();
            if (res.data.msg == "Reach maximum account usage") {
              this.$message({
                message: this.$t("_testplaywrong"),
                type: "error",
                center: true,
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                center: true,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    runLoadingFun() {
      console.log("runLoadingFun");
      setTimeout(() => {
        if (Math.floor(Math.random() * 3) !== 0) {
          this.color_line_vlaue += Math.floor(Math.random() * 50) + 1;
          // this.color_line_vlaue += 20;
        }
        if (this.color_line_vlaue < 100) {
          this.runLoadingFun();
        } else {
          this.color_line_vlaue = 100;
          setTimeout(() => {
            this.$refs.loading_line.style.opacity = `0`;
          }, 500);
          setTimeout(() => {
            // this.$refs.loading_line.style.display = `none`;
            // this.$refs.login_box.style.display = `block`;
            this.$refs.login_box.style.opacity = `1`;
          }, 1000);
          setTimeout(() => {
            this.$refs.login_box.style.transform = `translateY(-50px)`;
          }, 1100);
        }
        this.$refs.color_line.style.width = `${this.color_line_vlaue}%`;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.loginView3 {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  .item_box {
    padding-bottom: 80px;
    z-index: 200;
    // transition: all 500ms ease-in-out;
    .logo {
      margin: 10px auto 10px;
      width: 200px;
    }

    .loading_line {
      transform: translateY(100px);
      width: 80%;
      max-width: 700px;
      height: 20px;
      background-color: #8888;
      margin: 0 auto 20px;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      transition: all 500ms ease-in-out;
      opacity: 100;
      .color_line {
        height: 100%;
        width: 0%;
        background-color: #f9c900;
        border-radius: 50px;
        transition: all 500ms ease-in-out;
        z-index: 1;
        overflow: hidden;
        position: relative;
      }
      .number {
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 20px;
        left: 0;
        right: 0;
        top: 0;
        font-weight: bold;
        z-index: 3;
        color: #000;
      }
    }

    .login_box {
      opacity: 0;
      transform: translateY(300px);
      transition: all 500ms ease-in-out;
      margin: 0 auto;
      background-color: #0008;
      width: 90%;
      max-width: 600px;
      height: 300px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      // .img {
      //   width: 40%;
      //   height: 100%;
      //   background-color: #8888;
      // }
      .input_box {
        .input {
          width: calc(100% - 20px);
          height: 50px;
          margin: 10px;
          border-radius: 10px;
          padding: 10px;
          font-size: 16px;
          &::placeholder {
            color: var(--placeholderColor); // 动态值
          }
        }
        .btn {
          width: calc(60% - 20px);
          height: 40px;
          color: #000;
          margin: 10px auto;
          border-radius: 50px;
          font-size: 16px;
          line-height: 40px;
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
  .mobile_lan {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #0008;
    border-radius: 22px;
    display: block !important;
    width: 40px;
    height: 40px;
    overflow: hidden;
    z-index: 300;
    .lan_btn {
      padding: 4px;
      margin: 4px;
      border-radius: 100px;
      cursor: pointer;
      .lan_img {
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        padding: 13px;
      }
    }
  }
  .version {
    position: fixed;
    // top: calc(100vh - 30px);
    bottom: 10px;
    left: 10px;
    color: #fff;
    z-index: 300;
  }
  .girl {
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    width: 100%;
    height: 80%;
    position: absolute;
    // top: calc(100vh - 80%);
    bottom: 0;
    right: -100px;
    color: #fff;
    z-index: 10;
    // transition: all 500ms ease-in-out;
  }
}

@media screen and (max-width: 500px) {
  .loginView3 {
    align-items: start !important;
    .item_box {
      margin-top: 20px;
    }
    .logo {
      // margin: 10px auto -5% !important;
      width: 30% !important;
    }
    .girl {
      // top: calc(100vh - 100px) !important;
      bottom: -50px !important;
      right: 0px !important;
    }
  }
}

@media screen and (max-height: 500px) {
  .logo {
    display: none;
  }
}
</style>
